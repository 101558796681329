const en = {
    welcomeDescription: 'Hello!',
    exploreHeader: 'Explore',    
    buttonClicked: 'Click',
    aboutMe: 'DADUPA',
    project: 'All projects',
    listproject: 'Lists of projects',
    'signup.message1': 'Welcome on DADUPA,',
    'signup.message2': 'The social network for entrepreneurs',
    email: 'E-mail',
    'signup.form.email': 'Type your e-mail address',
    password: 'Password', 
    next: 'Next',
    previous: 'Previous',
    review: 'Review Details',
    targetAreas: 'Head (HQ)',
    funding: 'Funding',
    'funding.startup': 'Funded',
    'filter.secteur.finance': 'Funding',
    'filter.secteur.none': 'None',
    'filter.secteur': 'Sector',
    'title': 'Title',
    sector: 'Industry',
    status: 'Status',
    'signup.message3': 'By signing, I agree to the Terms of Use',
    'signup.message4': 'as well as the DADUPA Data Policy.',
    signUpWith : 'Or signup with:',
    'signup.form.Member': 'Already subscribed? Sign in!',
    'signup.form.alreadyMember': 'Already a member ?',
    signin: 'Sign In',
    q_register: "Don't have an account ?",
    firstName: 'First name',
    lastName: 'Last name',
    username: 'Username',
    'signup.form.profilType': 'You are ?',
    projectHolder: 'Startups',
    donor: 'VCs',
    accompanyingPerson: 'Hubs',
    phone: 'Phone number',
    country: 'Country',
    'filter.country.other': 'OTHER: (Please specify)',
    city: 'City',
    step: 'Step',
    'signup.review.step1': 'Step 1',
    'signup.review.step2': 'Step 2',
    edit: 'Edit',
    'signup.review.profileType': 'You are ?',
    signup: 'Signup',
    'footer.copyright': '2023 All rights reserved. DADUPA Consulting SARL',
    'footer.menu.about': 'About',
    'footer.menu.user_agreement': "Terms of Use",
    'footer.menu.privacy': "Data Policy ",
    'footer.menu.help': "Help & FAQ",
    'footer.menu.media': "Press & Medias",
    'footer.menu.jobs': "Internships & Jobs",
    'footer.menu.Services': "dadupa Services",
    'footer.menu.community': "Social Media",
    'header.menu.project' : 'Startups',
    'header.menu.finance' : 'VCs',
    'header.menu.mentors' : 'Hubs',
    see_profile: 'View my profile',
    my_offre: 'My offers',
    my_favorite: 'My favorites',
    favorite_page:'My favorites',
    setting: 'Settings',
    logout: 'Logout',
    coveredit:'Edit cover',
    searching_funder:'Fundraising',
    searching_mentor:'Seeking Mentorship',
    bio:'Bio',
    industry:'Industry',
    address:'Location',
    writesmthng: 'Share something...',	
    image: 'Images',
    video: 'Video',
    youtube: 'YouTube',
    'filter.typePost.public': 'Everyone',
    'filter.typePost.connexionsOnly': 'Network only',
    'filter.typePost.teamOnly': 'Team only',
    'filter.typePost.meOnly': 'Me only',
    publish: 'Publish',
    subscriptionKickoff: 'Joined DADUPA :',
    hoursingle:'an hour ago',
    hourplural:'hours ago',
    daysingle :'a day ago',
    dayplural :'days ago',
    weeksingle :'a week ago',
    weekplural :'weeks ago',
    monthsingle : 'a month ago',
    monthplural : 'months ago',
    seeAll: 'SEE ALL',
    tips:'DADUPA Tips',
    viewsingle:'view',
    viewplural:'views', 
    skills: 'Skills',
    'skills.form.new': 'Skill',
    add: 'Add',
    training: 'Training',
    start: 'Start',
    end: 'End',
    ongoing: 'Ongoing',
    school: 'School',
    trainingDiploma: 'Diploma',
    trainingSpecialty: 'Training specialty',
    'filter.trainingSpecialty.business': 'Business administration and management',
    'filter.trainingSpecialty.agriculture': 'Agriculture',
    'filter.trainingSpecialty.arts': 'Arts',
    'filter.trainingSpecialty.architecture': 'Architecture',
    'filter.trainingSpecialty.internationalBusiness': 'International business',
    'filter.trainingSpecialty.publicAdministration': 'Public administration',
    'filter.trainingSpecialty.biochemistry': 'Biochemistry',
    'filter.trainingSpecialty.biotechnology': 'Biotechnology',
    'filter.trainingSpecialty.banking': 'Banking',
    'filter.trainingSpecialty.biology': 'Biology',
    'filter.trainingSpecialty.accounting': 'Accounting',
    'filter.trainingSpecialty.chemistry': 'Chemistry',
    'filter.trainingSpecialty.internationalTrade': 'International trade',
    'filter.trainingSpecialty.communication': 'Communication',
    'filter.trainingSpecialty.trade': 'Commerce',
    'filter.trainingSpecialty.law': 'Law',
    'filter.trainingSpecialty.design': 'Graphic design',
    'filter.trainingSpecialty.economy': 'Economy',
    'filter.trainingSpecialty.energy': 'Energy',
    'filter.trainingSpecialty.finance': 'Finance',
    'filter.trainingSpecialty.management': 'Management',
    'filter.trainingSpecialty.construction': 'Civil engineering',
    'filter.trainingSpecialty.industrialEngineering': 'Industrial engineering',
    'filter.trainingSpecialty.electricalEngineering': 'Electrical engineering',
    'filter.trainingSpecialty.mecanicalEngineering': 'Mechanical engineering',
    'filter.trainingSpecialty.computerScience': 'IT',
    'filter.trainingSpecialty.marketing': 'Marketing',
    'filter.trainingSpecialty.maths': 'Mathematics',
    'filter.trainingSpecialty.projectManagement': 'Project management',
    'filter.trainingSpecialty.neuroscience': 'Neuroscience',
    'filter.trainingSpecialty.physics': 'Physics',
    'filter.trainingSpecialty.advertisement': 'Advertising',
    'filter.trainingSpecialty.hr': 'Human ressources',
    'filter.trainingSpecialty.health': 'Healthcare',
    'filter.trainingSpecialty.tourism': 'Tourism, hospitality and travel',
    'filter.trainingSpecialty.other': 'OTHER: (Please specify)',
    description: 'Description', 
    experience: 'Experiences',
    company: 'Company',
    job: 'Position',
    'filter.job.actuaire': 'Actuary',
    'filter.job.admin': 'Administrator',
    'filter.job.commercial': 'Sales Agent',
    'filter.job.immobilier': 'Real Estate Agent',
    'filter.job.analystecomm': 'Sales Analyst',
    'filter.job.analyst': 'Analyst',
    'filter.job.architect': 'Architect',
    'filter.job.architectsi': 'Information Systems Architect',
    'filter.job.artist': 'Artist',
    'filter.job.animart': 'Artistic Animator',
    'filter.job.assistant': 'Assistant',
    'filter.job.partner': 'Partner',
    'filter.job.managingPartner': 'Managing partner',
    'filter.job.seniorPartner': 'Senior Partner',
    'filter.job.insurer': 'Insurer',
    'filter.job.audit': 'Financial Auditor',
    'filter.job.lawyer': 'Lawyer',
    'filter.job.banker': 'Banker',
    'filter.job.blogger': 'Blogger',
    'filter.job.cameraman': 'Cameraman',
    'filter.job.cartographe': 'Cartographer',
    'filter.job.ceramist': 'Ceramist',
    'filter.job.expertcompt': 'Chartered Accountant',
    'filter.job.volunteer': 'Volunteer',
    'filter.job.biotech': 'Bioinformatician',
    'filter.job.biologist': 'Biologist',
    'filter.job.seniorExécutive': 'Senior exeecutive',
    'filter.job.officeManager': 'Office manager',
    'filter.job.teamLeader': 'Team leader',
    'filter.job.chercheuragronomie': 'Researcher in Agronomy',
    'filter.job.chercheurbio': 'Researcher in Biology, Medicine and Health',
    'filter.job.chercheurchimie': 'Research Chemist',
    'filter.job.chercheurdroit': 'Researcher in Law',
    'filter.job.chercheurecologie': 'Researcher in Ecology',
    'filter.job.chercheureconomie': 'Researcher in Economics',
    'filter.job.chercheurmath': 'Researcher in Mathematics',
    'filter.job.chercheurphysics': 'Research physicist',
    'filter.job.chercheurespace': 'Researcher in Earth, Universe and Space Sciences',
    'filter.job.chercheurtic': 'Researcher in Information and Communication Science and Technology',
    'filter.job.chercheurhumanities': 'Researcher in Humanities and Social Sciences',
    'filter.job.chimiste': 'Chemist',
    'filter.job.coach': 'Coach',
    'filter.job.cofounder': 'Co-founder',
    'filter.job.commissairecomptes': 'Auditor',
    'filter.job.comptable': 'Accountant',
    'filter.job.consultant': 'Consultant',
    'filter.job.controlgestion': 'Management auditor',
    'filter.job.coordinator': 'Coordinator',
    'filter.job.designer': 'Designer',
    'filter.job.designeranim': 'Animation Designer',
    'filter.job.designerimage': 'Multidimensional Image Designer',
    'filter.job.designerimageinteractif': 'Designer of Interactive Artworks',
    'filter.job.designergraphiste': 'Graphic Designer',
    'filter.job.dessinateurindus': 'Industrial Designer',
    'filter.job.developpeur': 'Developer',
    'filter.job.director': 'Director',
    'filter.job.ceo': 'CEO',
    'filter.job.coo': 'COO',
    'filter.job.cmo': 'Chief Marketing Officer',
    'filter.job.cto': 'CTO',
    'filter.job.hrd': 'Director of Human Resources',
    'filter.job.cis': 'Director, Information Systems',
    'filter.job.communicationsDirector': 'Directeur communication',
    'filter.job.partnershipManager': 'Director of Communications',
    'filter.job.writer': 'Writer',
    'filter.job.electricien': 'Electrician',
    'filter.job.electronicien': 'Electronic technician',
    'filter.job.entrepreneur': 'Entrepreneur',    
    'filter.job.farmer': 'Farmer',
    'filter.job.fiscaliste': 'Tax specialist',
    'filter.job.founder': 'Founder',
    'filter.job.freelance': 'Freelance',
    'filter.job.geographe': 'Geographer',
    'filter.job.geologist': 'Geologist',
    'filter.job.graphist': 'Graphist',
    'filter.job.history': 'Historian',
    'filter.job.hostel': 'Hotelier',
    'filter.job.hydrobiology': 'Hydrobiologist',
    'filter.job.inforgraphist': '2D - 3D graphic designer',
    'filter.job.ingaeronautique': 'Aeronautical Engineer',
    'filter.job.ingaerospatial': 'Aerospace Engineer',
    'filter.job.ingagronome': 'Agricultural Engineer',
    'filter.job.ingchemist': 'Chemical Engineer',
    'filter.job.ingmanufacturing': 'Manufacturing Engineer',
    'filter.job.ingelectricien': 'Electrical Engineer',
    'filter.job.ingenergie': 'Energy Engineer',
    'filter.job.ingrobotique': 'Robotics Engineer',
    'filter.job.ingenvironment': 'Environmental Engineer',
    'filter.job.inglogiciel': 'Software Engineer',
    'filter.job.ingmecanique': 'Mechanical Engineer',
    'filter.job.ingmicrosystem': 'Microsystems Engineer',
    'filter.job.ingnanosystem': 'Nanosystems Engineer',
    'filter.job.ingnucleaire': 'Nuclear Engineer',
    'filter.job.ingprojet': 'Project Engineer',
    'filter.job.ingquality': 'Quality Engineer',
    'filter.job.ingsystem': 'Systems Engineer',
    'filter.job.ingindustriel': 'Industrial Engineer',
    'filter.job.investor': 'Investor',
    'filter.job.jurist': 'Legal expert',
    'filter.job.laborantin': 'Laboratory technician',
    'filter.job.Logistician': 'Logistician',
    'filter.job.manager': 'Manager',
    'filter.job.machinist': 'Machinist',
    'filter.job.exportImport': 'Trader Exporter/Importer',
    'filter.job.doctor': 'Medical doctor',
    'filter.job.mentor': 'Mentor',
    'filter.job.microbiologiste': 'Microbiologist',
    'filter.job.notaire': 'Notary',
    'filter.job.nutrition': 'Nutritionnist',
    'filter.job.pharmacist': 'Pharmacist',
    'filter.job.photographer': 'Photograph',
    'filter.job.physician': 'Physician',
    'filter.job.chairman': 'Chairman',
    'filter.job.president': 'President',
    'filter.job.dg': 'Director General',
    'filter.job.principal': 'Principal',
    'filter.job.copywriter': 'Copywriter',
    'filter.job.projectmanager': 'Project Manager',
    'filter.job.programManager': 'Program Manager', 
    'filter.job.marketingmanager': 'Marketing Manager',
    'filter.job.portfolioManager': 'Portfolio manager',
    'filter.job.complianceOffice': 'Compliance Officer',
    'filter.job.qualityControlManager': 'Quality control manager',
    'filter.job.digitalMarketingManager': 'Digital marketing manager',
    'filter.job.seoSpecialist': 'Head of SEO',
    'filter.job.socialMediaManager': 'Head of social media',
    'filter.job.paidAdvertisingSpecialist': 'Head of paid advertising',
    'filter.job.sociologue': 'Sociologue',
    'filter.job.statistics': 'Statistician',
    'filter.job.intern': 'Intern',
    'filter.job.technician': 'Technician',
    'filter.job.traducteur': 'Translator',
    'filter.job.independent': 'Self-employed',
    'filter.job.vicePresident': 'Vice president',
    'filter.job.webmaster': 'Webmaster',
    'filter.job.analystefinance': 'Financial Analyst',
    'filter.job.journalism': 'Journalist',
    'filter.job.analystegestion': 'Management Analyst',
    'filter.job.sound': 'Music Arranger',
    'filter.job.specialeffects': 'Special Effects Technician',
    'filter.job.content': 'Web Editor',
    'filter.job.other': 'OTHER: (Please specify)',
    industry: 'Industry',
    'filter.secteur.agroalimentaire': 'Agribusiness',
    'filter.secteur.architecture': 'Architecture',
    'filter.secteur.art': 'Art',
    'filter.secteur.automobile': 'Automotive',
    'filter.secteur.aérospace': 'Aerospace',
    'filter.secteur.big_data': 'Big data',
    'filter.secteur.bio': 'Bio technology',
    'filter.secteur.btp': 'Construction',
    'filter.secteur.b2bSaas': 'B2B SaaS',
    'filter.secteur.commerce': 'Sales',
    'filter.secteur.internationalCommerce': 'Commerce international',
    'filter.secteur.communication': 'Communications',
    'filter.secteur.climate': 'Climat',
    'filter.secteur.crypto': 'Crypto/Blockchain',
    'filter.secteur.design': 'Design',
    'filter.secteur.divertissement': 'Entertainment',
    'filter.secteur.droit': 'Law',
    'filter.secteur.eau': 'Water',
    'filter.secteur.ecommerce': 'E-commerce',
    'filter.secteur.education': 'Education',
    'filter.secteur.electronics': 'Electronics',
    'filter.secteur.energie': 'Energy',
    'filter.secteur.environement': 'Environment',
    'filter.secteur.fintech': 'Financial Services',
    'filter.secteur.waste': 'Waste management',
    'filter.secteur.immobilier': 'Real estate',
    'filter.secteur.ia': 'Artificial Intelligence',
    'filter.secteur.internet_objets': 'Internet of Things',
    'filter.secteur.game': 'Gaming',
    'filter.secteur.logistic': 'Logistics',
    'filter.secteur.marketPlace': 'Market place',
    'filter.secteur.medias': 'Media',
    'filter.secteur.mode': 'Fashion',
    'filter.secteur.ads': 'Advertising',
    'filter.secteur.jobs': 'Human Resources',
    'filter.secteur.robotique': 'Robotics',
    'filter.secteur.vr': 'Virtual reality',
    'filter.secteur.sante': 'Health and well-being',
    'filter.secteur.service': 'Services',
    'filter.secteur.security': 'Security',
    'filter.secteur.sport': 'Sport and fitness',
    'filter.secteur.retail': 'Retail',  
    'filter.secteur.information': 'Information',  
    'filter.secteur.technologie': 'Technology',
    'filter.secteur.telecom': 'Telecommunications',
    'filter.secteur.textile': 'Clothing',
    'filter.secteur.tourism': 'Tourism, hotels and travel',
    'filter.secteur.transport': 'Transport',
    'filter.secteur.other': 'OTHER: (Please specify)',
    accomplishments: 'Achievements',
    offerings:'Offers',
    history:'History',
    réseaux:'DADUPERS',
    friends: 'Network',
    requests: 'Requests',
    suggestions: 'DADUPERS suggestions',
    sortedBy: 'Sorted by:',
    'filter.sortedBy.name1': 'Recently added',
    'filter.sortedBy.name2': 'First name',
    'filter.sortedBy.name3': 'Last name',
    'filter.sortedBy.type1': 'Entrepreneur',
    'filter.sortedBy.type2': 'Investor',
    'filter.sortedBy.type3': 'Mentor',
    searchContact: 'Search a DADUPER',    
    'header.menu.new' : 'New offer',
    'project.add.desc1': 'Showcase your offer',
    'offer.new.message2': 'Fill in your offer information',
    'project.add.detail_offre': "Showcase key info",
    'form.prject_name': "Project name",
    projectState: "Status",
    'filter.etat_projet.idee': 'Idea',
    'filter.etat_projet.prototype': 'Prototype',
    'filter.etat_projet.mvp': 'MVP',
    'filter.etat_projet.first_sale': 'Growth',
    fundingSearch: "Financing wanted",
    'filter.secteur.99': '$ 99,9K or less',
    'filter.secteur.100-999': '$ 100K - $ 999,9K',
    'filter.secteur.1m': '$ 1M - and Beyond',
    'form.add_logo': 'Add logo',
    'form.want_mentors': 'I\'m looking for mentors',
    'funder.form.proposition': 'I also provide mentoring',
    'form.add_url': 'Website',
    url: 'URL',
    'upload_video': 'Pitch (Video)',
    uploadVideoMessage1: 'Select multimedia files to import',
    uploadVideoMessage2: 'or drag-and-drop',
    uploadVideoBouton: 'Import',
    uploadlinkMessage1: 'Import videos from YouTube or Vimeo',
    uploadlinkMessage2: 'Copy and paste your video link here',
    uploadlinkBouton: 'Import',
    'form.uploadlink': 'Paste link here',
    previewlinkBouton: 'Preview',
    desc_offre: 'Project description',
    addTags: 'Add tags (Press ENTER to add)',
    tags: 'Tags',
    'form.edit_logo': "Edit logo",
    'review_detail': "Review",
    'funder.add.desc1': 'Present your funding offer',
    'funder.form.you_are': "You are",
    'funder.form.you_are.business_angle' : 'Angel investor',
    'funder.form.you_are.fonds' : 'VC',
    'funder.form.you_are.corporate' : 'Corporate',    
    'funder.form.financement': "Funding capacity",
    'funder.form.date': "Deadline",
    'header.menu.mentoring' : 'Mentoring',
    'form.new.mentoringType': "Modality",
    'filter.new.mentoringType.free' : 'Free',
    'filter.new.mentoringType.paid' : 'Paid',
    'form.new.mentorType': "You are :",
    'mentor.form.individual' : 'Individual',
    'mentor.form.incubator' : 'Incubator',
    'mentor.form.accelerator' : 'Accelerator',
    'form.add.coEntrepreneur': "Add your team members",
    'form.add.coInvestor': "Add Co-investors",
    'form.add.coMentor': "Add co-mentors",
    newComment: 'Write a comment',
    shareplural : 'Shares',
    sharesingle:'Share',
    partagez:"Share",
    commentplural:'comments',
    comment:'Comment',
    commentsingle:'Comment',
    clap: 'Bravo',
    details: 'Details',
    documents: 'Documents',
    noContentAvailable: 'No available content',
    favoriteConfirmationMessage1: 'Confirm addition to favorites',
    favoriteConfirmationMessage2: 'Are you sure you want to bookmark this content?',
    favoriteDeleteMessage1: 'Confirm favorite removal',
    favoriteDeleteMessage2: 'Are you sure you want to remove this content from your favorites?',
    no: 'No',
    yes: 'Yes',
    notifications : 'Notifications',
    clapnotification: 'says well done',
    commentnotification:'commented on your post',
    sharingnotification:'shared your post',
    requestnotification:'would like to join your network',
    cofoundernotification:'invites you to become a team member',
    cofounderconfirmation:'is one of the team members of your offer',
    coinvestorNotification:'invites you to become a Co-investor',
    coinvestorConfirmation:'is one of the Co-investors in your offer',
    comentorNotification:'invites you as a Co-mentor',
    comentorConfirmation:'is one of the co-mentors of your offer',
    unread: 'Marked as unread',
    mask:'Mask',
    seeMore:'See more',
    generalsettings:'General settings',
    general: 'General',
    account:'My account',
    accountUpdate: 'Account update',
    settingsettup:'Setup your account',
    access:'My access',
    access_details:'My access details',
    forget_password:'Forgot password?',
    passwordUpdate: 'Password update',
    'form.presentPassword': 'Current password',
    'form.newPassword': 'New password',
    emailConfirmation: 'Resend confirmation e-mail',
    settingconfirm: 'Confirm',
    interface:'Interface',
    update: 'Update',
    refresh:'Refresh',
    remember_me: 'Remember me',
    invitationsToConsent: 'Requests to approve',
    sentInvitations: 'Requests sent',
    receivedInvitations: 'Requests received',
    askAccess: 'Access requested',
    to: 'To',
    from: 'From',
    categorie: 'Categorie',
    link: 'Link',
    role: 'Role',
    date: 'Date',
    action: 'Action',
    'header.menu.articles' : 'Oppotunities',
    'form.menu.articles.title': 'Title',
    'form.menu.articles.category': 'Categorie',
    'filter.articles.category.calls4projects': 'Calls for projects',
    'filter.articles.category.fundraising': 'Fundraising news',
    'filter.articles.category.news': 'News',
    suggestedArticles: 'Suggested articles',
    topArticles: 'Top articles',
    already_a_member:'Already a member ?',
    or_sign_up_with:'Or sign up with',
    login: 'Login',
    'error.credentials' : "These credentials do not match our records.",
    'messages' : 'Messages',
    'filter.search': 'Keywords',
    or_copy_link : 'Or copy link',
    'choose_file': 'Choose file',
    'filter.fund.type': 'Fund category',
    'filter.fundtype.preseed':'Pre-seed Capital',
    'filter.fundtype.seed':'Seed Capital',
    'filter.fundtype.venturecap':'Venture Capital',
    'has_deadline': 'No deadline',
    'postuler': 'Apply',
    'target_zone': 'Target zone',
    'target_sector': 'Targeted industry',
    'published_at': 'Published on',
    'write_comment': 'Write your comment',
    writeMessage: 'Type message here',
    onligne: 'Online',
    newMessage: 'New message',
    seeallmsgs:'See all messages',
    multimedia:'Photos / Videos',
    sponsored:'Sponsored',
    filter_selection: 'selected',
    'no_notifications': 'No notifications available',
    'no_access_to_content_alert': 'You do not have authorization to access this content. Please contact the owner to request access.',
    'no_access_to_content_popup': 'Please contact the owner to request access.',
    'zone':'Zone',
    'owners': 'Overview',
    noNotifAvailable: 'No notifications available',
    report:'Report',
    'report_select_problem':'Please indicate a concern',
    'report_select_problem_message':'Because we value your safety and the integrity of the community, your vigilance is essential in maintaining a respectful and secure environment for all users.',
    'report_level':'Indicate the report\'s gravity',
    'report_low' : 'Low',
    'report_medium' : 'Moderate', 
    'report_high' : 'High',
    'report_select_idea' : 'Plagiarism',
    'report_select_nudity' : 'Inappropriate content',
    'report_select_violence' : 'Harassment',
    'report_select_spam' : 'Spam',
    'report_select_speech' :'Hate speech',
    'report_select_information' :'False information',
    'report_select_bots' : 'Fake account',
    'report_select_funds' : 'Misuse of funds',
    send:'Send',
    unclap: 'Unclap',
    reply:'Reply',
    'select_country':'Select a country',
    'send_invite':'Connect',
    searching_funder_descrip:'This account would like to connect with investors.',
    searching_mentor_descrip:'This account would like to connect with mentors.',
    articles: 'Opportunities',
    search: 'Search',
    all: 'All',
    provide_mentoring_descrip:'This account provides mentoring.',
    provide_mentoring:'Funding & Mentoring',
    delete:'Delete',
    cancel:'Cancel',
    edit_comment:'Edit comment',
    favorite:'Favorites',
    name_of_skill:'Skill name',
    please_confirm_your_email_address:'Please confirm your email address',
    please_activate_your:'Please activate your',
    hi:'Hi',
    in_order_to_start_using_your_account_you_need_to_confirm_your_email:'In order to start using your account, you need to confirm your email',
    objet_welcome_mail:'Welcome to DADUPA:',
    welcome_ligne1:'Hi!',
    welcome_ligne2:'Welcome to DADUPA, the social network for entrepreneurs.',
    welcome_ligne3:'Without further ado, unleash your potential with this unique community of entrepreneurs, investors and mentors.',
    welcome_ligne4:'Back to DADUPA',
    welcome_ligne5:'If you did not sign up for this account you can ignore this email and the account will be deleted automatically after 30 days',
    welcome_ligne6:'For more information, please visit the Help Center or contact us.',
    welcome_ligne7:'The DADUPA Team',
    objet_msg_notif : 'left you a message',
    msg_notif_ligne1: 'Hello!', 
    msg_notif_ligne2: 'You have a new message.',
    msg_notif_ligne3: 'View message',
    msg_notif_ligne4: 'For more information, please visit the Help Center or contact us.',
    msg_notif_ligne5: 'The DADUPA Team',
    objet_request_notif: 'sent you a connection request',
    request_ligne1: 'Hello!',
    request_ligne2: 'wishes to be part of your connections on DADUPA.',
    request_ligne3_left: 'View profile',
    request_ligne3_right: 'Agree',
    request_ligne4: 'For more information, please visit the Help Center or contact us.',
    request_ligne5: 'The DADUPA Team',
    reset_password: 'Reset password',
    you_are_receiving_this_email_because_we_received_a_password_reset_request_for_your_account: 'You are receiving this email because we received a password reset request for your account.',
    reset_your_password: 'Reset your password',
    if_you_did_not_request_a_password_reset_no_further_action_is_required: 'If you did not request a password reset, no further action is required.',
    contact_support_team: 'Contact Support team',    
    you_have_successfully_reset_your_password: 'You have successfully reset your password',
    this_is_a_confirmation_email_that_your_password_for_your_account: 'This is a confirmation email that your password for your account has just been changed.',    
    if_this_is_your_account_but_you_didnt_request_a_password_change_you_can_reset_your_password_here_if_you_re_having_trouble: 'If you see an unauthorized password change on your account, reset your password here. For any issues, please contact us.',
    objet_comment_notif: 'has commented on your venture',
    comment_notif_ligne1: 'Hello!', 
    comment_notif_ligne2: 'See the comment',
    comment_notif_ligne3: 'For more information, please visit the Help Center or contact us.',
    comment_notif_ligne4: 'The DADUPA Team',
    objet_article_notif: 'has commented on your article',
    article_notif_ligne1: 'Hello!', 
    article_notif_ligne2: 'See the comment',
    article_notif_ligne3: 'For more information, please visit the Help Center or contact us.',
    article_notif_ligne4: 'The DADUPA Team',
    objet_post_notif: 'has commented on your post',
    post_notif_ligne1: 'Hello!', 
    post_notif_ligne2: 'See the comment',
    post_notif_ligne3: 'For more information, please visit the Help Center or contact us.',
    post_notif_ligne4: 'The DADUPA Team',
    email_not_confirmed: 'Your e-mail is not confirmed',
    french: 'French',
    english: 'English',
    pictures: 'Pictures',
    visit_website: 'Visit the website',
    'footer.menu.cookie': "Help & FAQ",
    'footer.menu.copyright': '2022 All Rights Reserved. Dadupa Consulting SARL',
    'footer.menu.brand': "interships & Careers",
    'footer.menu.guest': "Dadupa Services",
    'form.profilsearchby': 'Search by',
    select_a_country : 'Select a country',
    auth_response_error : 'E-mail or Password incorrect !',
    'password.8caracteresminimum' : 'Password: 8 characters minimum',
    request_sent_accept : 'Connection request sent',
    'form.want_funder' : 'I\'m looking for investors',
    'Select': 'Select',

    afghanistan:'Afghanistan',
    åland_Islands:'Åland Islands',
    albania:'Albania',
    algeria:'Algeria',
    american_samoa: 'American Samoa',
    andorra: 'Andorra',
    angola: 'Angola',
    anguilla: 'Anguilla',
    antarctica: 'Antarctica',
    antigua_and_barbuda: 'Antigua and Barbuda',
    argentina: 'Argentina',
    armenia: 'Armenia',
    aruba: 'Aruba',
    australia: 'Australia',
    austria: 'Austria',
    azerbaijan: 'Azerbaijan',
    bahamas:'Bahamas',
    bahrain:'Bahrain',
    bangladesh: 'Bangladesh',
    barbados: 'Barbados',
    belarus: 'Belarus',
    belgium: 'Belgium',
    belize: 'Belize',
    benin: 'Benin',
    bermuda: 'Bermuda',
    bhutan: 'Bhutan',
    bolivia: 'Bolivia',
    bonaire: 'Bonaire',
    bosnia: 'Bosnia',
    botswana: 'Botswana',
    bouvet_Island: 'Bouvet Island',
    brazil: 'Brazil',
    british_Indian_Ocean_Territory: 'British Indian Ocean Territory',
    brunei_Darussalam: 'Brunei_Darussalam',
    bulgaria: 'Bulgaria',
    burkina_Faso: 'Burkina Faso',
    burundi: 'Burundi',
    cabo_Verde: 'Cabo Verde',
    cambodia: 'Cambodia',
    cameroon: 'Cameroon',
    canada: 'Canada',
    cayman_Islands: 'Cayman Islands',
    central_African_Republic: 'Central African Republic',
    chad: 'Chad',
    chile: 'Chile',
    china: 'China',
    christmas_Island: 'Christmas Island',
    colombia: 'Colombia',
    comoros: 'Comoros',
    congo: 'Congo',
    congo_Democratic_Republic_of_the: 'Congo, Democratic Republic',
    cook_Islands: 'Cook Islands',
    costa_Rica: 'Costa Rica',
    croatia: 'Croatia',
    cuba: 'Cuba',
    curaçao: 'Curaçao',
    cyprus: 'Cyprus',
    czechia: 'Czechia',
    côte_dIvoire: 'Côte dIvoire',
    denmark: 'Denmark',
    djibouti: 'Djibouti',
    dominica: 'Dominica',
    dominican_Republic: 'Dominican Republic',
    ecuador: 'Ecuador',
    egypt: 'Egypt',
    el_Salvador: 'El Salvador',
    equatorial_Guinea: 'Equatorial Guinea',
    eritrea: 'Eritrea',
    estonia: 'Estonia',
    eswatini: 'Eswatini',
    ethiopia: 'Ethiopia',
    falkland_Islands_Malvinas: 'Falkland Islands (Malvinas)',
    faroe_Islands: 'Faroe Islands',
    fiji: 'Fiji',
    finland: 'Finland',
    france: 'France',
    french_Guiana: 'French Guiana',
    french_Polynesia: 'French Polynesia',
    french_Southern_Territories: 'French Southern Territories',
    gabon: 'Gabon',
    gambia: 'Gambia',
    georgia: 'Georgia',
    germany: 'Germany',
    ghana: 'Ghana',
    gibraltar: 'Gibraltar',
    greece: 'Greece',
    greenland: 'Greenland',
    grenada: 'Grenada',
    guadeloupe: 'Guadeloupe',
    guam: 'Guam',
    guatemala: 'Guatemala',
    guernsey: 'Guernsey',
    guinea: 'Guinea',
    guinea_Bissau: 'Guinea-Bissau',
    guyana: 'Guyana',
    haiti: 'Haiti',
    heard_Island_and_McDonald_Islands: 'Heard Island and McDonald Islands',
    holy_See: 'Holy See',
    honduras: 'Honduras',
    hong_Kong: 'Hong Kong',
    hungary: 'Hungary',
    iceland: 'Iceland',
    india: 'India',
    indonesia: 'Indonesia',
    iran_Islamic_Republicof: 'Iran, Islamic Republic',
    iraq: 'Iraq',
    isle_of_Man: 'Isle of Man',
    italy: 'Italy',
    jamaica: 'Jamaica',
    japan: 'Japan',
    jersey: 'Jersey',
    jordan: 'Jordan',
    kazakhstan: 'Kazakhstan',
    kenya: 'Kenya',
    kiribati: 'Kiribati',
    korea_Democratic_Peoples_Republic_of: 'Korea, Democratic People\'s Republic',
    korea_Republic_of: 'Korea, Republic',
    kuwait: 'Kuwait',
    kyrgyzstan: 'Kyrgyzstan',
    lao_Peoples_Democratic_Republic: 'Lao People\'s Democratic Republic',
    latvia: 'Latvia',
    lebanon: 'Lebanon',
    lesotho: 'Lesotho',
    liberia: 'Liberia',
    libya: 'Libya',
    liechtenstein: 'Liechtenstein',
    lithuania: 'Lithuania',
    luxembourg: 'Luxembourg',
    macao: 'Macao',
    madagascar: 'Madagascar',
    malawi: 'Malawi',
    malaysia: 'Malaysia',
    maldives: 'Maldives',
    mali: 'Mali',
    malta: 'Malta',
    marshall_Islands: 'Marshall Islands',
    martinique: 'Martinique',
    mauritania: 'Mauritania',
    mauritius: 'Mauritius',
    mayotte: 'Mayotte',
    mexico: 'Mexico',
    micronesia_Federated_States_of: 'Micronesia, Federated States',
    moldova_Republic_of: 'Moldova, Republic',
    monaco: 'Monaco',
    mongolia: 'Mongolia',
    montenegro: 'Montenegro',
    montserrat: 'Montserrat',
    morocco: 'Morocco',
    mozambique: 'Mozambique',
    myanmar: 'Myanmar',
    namibia: 'Namibia',
    nauru: 'Nauru',
    nepal: 'Nepal',
    netherlands: 'Netherlands',
    new_Caledonia: 'New Caledonia',
    new_Zealand: 'New Zealand',
    nicaragua: 'Nicaragua',
    niger: 'Niger',
    nigeria: 'Nigeria',
    niue: 'Niue',
    norfolk_Island: 'Norfolk Island',
    north_Macedonia: 'North Macedonia',
    northern_Mariana_Islands: 'Northern Mariana Islands',
    norway: 'Norway',
    oman: 'Oman',
    pakistan: 'Pakistan',
    palau: 'Palau',
    palestine_State_of: 'Palestine, State',
    panama: 'Panama',
    papua_New_Guinea: 'Papua New Guinea',
    paraguay: 'Paraguay',
    peru: 'Peru',
    philippines: 'Philippines',
    pitcairn: 'Pitcairn',
    poland: 'Poland',
    portugal: 'Portugal',
    puerto_Rico: 'Puerto Rico',
    qatar: 'Qatar',
    romania: 'Romania',
    russian_Federation: 'Russian Federation',
    rwanda: 'Rwanda',
    réunion: 'Réunion',
    saint_Barthélemy: 'Saint Barthélemy',
    saint_Helena_Ascension_and_Tristan_da_Cunha: 'Saint Helena, Ascension and Tristan da Cunha',
    saint_Kitts_and_Nevis: 'Saint Kitts and Nevis',
    saint_Lucia: 'Saint Lucia',
    saint_Martin: 'Saint Martin (French part)',
    saint_Pierre_and_Miquelon: 'Saint Pierre and Miquelon',
    saint_Vincent_and_the_Grenadines: 'Saint Vincent and the Grenadines',
    samoa: 'Samoa',
    san_Marino: 'San Marino',
    sao_Tome_and_Principe: 'Sao Tome and Principe',
    saudi_Arabia: 'Saudi Arabia',
    senegal: 'Senegal',
    serbia: 'Serbia',
    seychelles: 'Seychelles',
    sierra_Leone: 'Sierra Leone',
    singapore: 'Singapore',
    sint_Maarten: 'Sint Maarten (Dutch part)',
    slovakia: 'Slovakia',
    slovenia: 'Slovenia',
    solomon_Islands: 'Solomon Islands',
    somalia: 'Somalia',
    south_Africa: 'South Africa',
    south_Georgia_and_the_South_Sandwich_Islands: 'South Georgia and the South Sandwich Islands',
    south_Sudan: 'South Sudan',
    spain: 'Spain',
    sri_Lanka: 'Sri Lanka',
    sudan: 'Sudan',
    suriname: 'Suriname',
    svalbard_and_Jan_Mayen: 'Svalbard and Jan Mayen',
    sweden: 'Sweden',
    switzerland: 'Switzerland',
    syrian_Arab_Republic: 'Syrian Arab Republic',
    taiwan_Province_of_China: 'Taiwan, Province of China',
    tajikistan: 'Tajikistan',
    tanzania_United_Republic_of: 'Tanzania, United Republic',
    thailand: 'Thailand',
    timor_Leste: 'Timor-Leste',
    togo: 'Togo',
    tokelau: 'Tokelau',
    tonga: 'Tonga',
    trinidad_and_Tobago: 'Trinidad and Tobago',
    tunisia: 'Tunisia',
    turkey: 'Turkey',
    turkmenistan: 'Turkmenistan',
    turks_and_Caicos_Islands: 'Turks and Caicos Islands',
    tuvalu: 'Tuvalu',
    uganda: 'Uganda',
    ukraine: 'Ukraine',
    united_Arab_Emirates: 'United Arab Emirates',
    united_Kingdom: 'United Kingdom',
    united_States_Minor_Outlying_Islands: 'United States Minor Outlying Islands',
    united_States: 'United States',
    uruguay: 'Uruguay',
    uzbekistan: 'Uzbekistan',
    vanuatu: 'Vanuatu',
    venezuela_Bolivarian_Republic_of: 'Venezuela, Bolivarian Republic',
    vietNam: 'Viet Nam',
    virgin_Islands_British: 'Virgin Islands, British',
    virgin_Islands_US: 'Virgin Islands, U.S.',
    wallis_and_Futuna: 'Wallis and Futuna',
    yemen: 'Yemen',
    zambia: 'Zambia',
    zimbabwe: 'Zimbabwe',
};

export default en;